import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Asian Leather and Fetish Alliance" />
    <section className="intro">
      <div className="intro-container">
        <h2>The <strong>Asian Leather and Kink Alliance</strong> is an antiracist, intersectional and pro-decrim grassroots collective for folx of Asian descent in the kink, fetish, and BDSM community.</h2>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>We work in solidarity with abolitionist organizations to combat all oppression in kink and fetish community spaces. We fight for sex workers and marginalized members of the kink community and kinky Asians worldwide. We work towards inclusion, representation, safety, and trauma-aware policies and processes in kink, fetish, and BDSM spaces. <Link to="/about"><em>Learn more</em></Link></p>
      </div>
    </section>
    <section className="polyptych">
      <div className="polyptych-container">
        <div className="polyptych-third">
          <h3>About us</h3>
          <p>Learn more about the <em>Asian Leather and Kink Alliance</em> and the grassroots organizing we do in solidarity with abolitionist organizations. We believe in an end to violent policing, criminalization of sex work, and kink stigma.</p>
          <p><Link to="/about" className="call-to-action">About us</Link></p>
        </div>
        <div className="polyptych-fourth">
          <h3>ALKALine podcast</h3>
          <p>Listen to the <em>ALKALine</em> podcast, which aims to bring Asian voices to the kink and fetish community together with underserved and underrepresented voices in the kink and fetish worlds.</p>
          <p><Link to="/podcast" className="call-to-action">Listen to our podcast</Link></p>
        </div>
        <div className="polyptych-first">
          <h3>Resources</h3>
          <p>Learn more about <em>how you can help support and ally with Asians and other people of color</em> by combating anti-Blackness, misogyny, transphobia, ableism, and anti-Asian racism in solidarity with Asian kinksters and fetishists.</p>
          <p><Link to="/resources" className="call-to-action">See resources</Link></p>
        </div>
        <div className="polyptych-third">
          <h3>Join us</h3>
          <p>We welcome <em>all genders, all (gray) sexualities, and all Asian heritages</em>. We also take your privacy seriously, but we need a bit of information from you if you'd like to be part of our grassroots collective.</p>
          <p><Link to="/join" className="call-to-action">Join ALKA</Link></p>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
